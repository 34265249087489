import gql from 'graphql-tag'
import templateFragment, {
  templateTypeDetails,
  imageTemplateTypeDetails,
  videoTemplateTypeDetails
} from './fragments/templateFragment'

export const getFeaturedTemplates = gql`
  query featuredTemplates($authenticated: Boolean!) {
    featuredTemplates(first: 50) {
      ...templateDetails
    }
  }
  ${templateFragment}
`
export const getFavoritesQuery = gql`
  query getFavorites {
    me {
      id
      firstName
      favorites {
        id
        name
        previewUrl
        thumbnailUrl
        favorited
        hasAccess
        orientation {
          name
        }
        feel {
          name
        }
        energy {
          name
        }
        tags {
          name
        }
        type {
          typeName
          displayName
        }
        size {
          width
          height
        }
        platforms {
          name
        }
        categories {
          name
        }
        ... on ImageTemplate {
          id
        }
        ... on VideoTemplate {
          id
        }
      }
    }
  }
`
export const getTemplateCategoriesList = gql`
  query categoriesNameList {
    categories(pinned: true, first: 20) {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }
`
export const getAllTemplates = gql`
  query templates(
    $authenticated: Boolean!
    $first: Int!
    $after: String
    $search: TemplateSearchInput
  ) {
    templates(first: $first, after: $after, search: $search) {
      ...templateDetails
    }
  }
  ${templateFragment}
`
export const getAllCategories = gql`
  query categories($authenticated: Boolean!, $first: Int!, $after: String) {
    categories(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          name
          slug
          templates(first: 20) {
            ...templateDetails
          }
        }
      }
    }
  }
  ${templateFragment}
`
export const getTemplate = gql`
  query getTemplate($authenticated: Boolean!, $id: ID!) {
    template(id: $id) {
      ${templateTypeDetails}
    }
  }
`
export const getImageTemplate = gql`
  query ($id: ID!) {
    imageTemplate(id: $id) {
      ${imageTemplateTypeDetails}
    }
  }
`
export const getVideoTemplate = gql`
  query ($id: ID!) {
    videoTemplate(id: $id) {
      ${videoTemplateTypeDetails}
    }
  }
`
export const getTemplates = gql`
  query getTemplates(
    $authenticated: Boolean!
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: TemplateSearchInput
    $filter: TemplateFilterInput
  ) {
    templates(
      first: $first
      after: $after
      before: $before
      last: $last
      search: $search
      filter: $filter
    ) {
      ...templateDetails
    }
  }
  ${templateFragment}
`
export const getRecommendedTemplates = gql`
  query getRecommendedTemplates(
    $authenticated: Boolean!
    $first: Int!
    $after: String
    $orderBy: TemplateOrderByInput
    $id: ID!
  ) {
    recommendedTemplates(
      first: $first
      after: $after
      orderBy: $orderBy
      id: $id
    ) {
      ...templateDetails
    }
  }
  ${templateFragment}
`
export const getTemplatesFiltersValuesList = gql`
  query getTemplatesFiltersValuesList {
    businesses {
      id
      name
      isSelected @client
      __typename
    }

    types {
      id
      typeName
      displayName
      slug
      isSelected @client
      __typename
    }

    niches {
      id
      name
      slug
      isSelected @client
      __typename
    }

    categories(pinned: true, first: 20) {
      edges {
        node {
          id
          name
          slug
          isSelected @client
        }
      }
      __typename
    }

    orientations {
      id
      name
      slug
      xRatio
      yRatio
      isSelected @client
      __typename
    }

    platforms {
      id
      name
      slug
      isSelected @client
      __typename
    }

    feels {
      id
      name
      slug
      isSelected @client
      __typename
    }

    energies {
      id
      name
      slug
      isSelected @client
      __typename
    }
  }
`

export const getFont = gql`
  query font($name: String!, $variant: String!) {
    font(name: $name, variant: $variant) {
      id
      family {
        id
        name
      }
      postScriptName
      fontUrl
    }
  }
`

export const getConstructedStmtValues = gql`
  query getConstructedStmtValues {
    types {
      id
      typeName
      displayName
      slug
      isSelected @client
      __typename
    }

    actions {
      id
      name
      slug
      isSelected @client
      __typename
    }

    platforms {
      id
      name
      slug
      isSelected @client
      __typename
    }
  }
`
export const selectedActionQuery = gql`
  query selectedAction {
    selectedAction @client
  }
`
export const getTemplatesByName = gql`
  query templatesByName(
    $first: Int!
    $after: String
    $search: TemplateSearchInput
    $filter: TemplateFilterInput
  ) {
    templatesByName(
      first: $first
      after: $after
      search: $search
      filter: $filter
    ) {
      id
      name
      type {
        typeName
      }
      previewUrl
      size {
        width
        height
      }
      hasAccess
      assetCount {
        textAssets
        imageAssets
        videoAssets
      }
      thumbnailUrl
      description
      ... on VideoTemplate {
        duration
      }
    }
  }
`

export const getUserRecentTemplates = gql`
  query userRecentTemplate {
    userRecentTemplate {
      id
      name
      type {
        typeName
      }
      previewUrl
      size {
        width
        height
      }
      hasAccess
      assetCount {
        textAssets
        imageAssets
        videoAssets
      }
      thumbnailUrl
      description
      ... on VideoTemplate {
        duration
      }
    }
  }
`

export const getTemplatesUseCases = gql`
  query getTemplatesUseCases($action: String!) {
    useCases(action: $action) {
      id
      name
      slug
      isSelected @client
      __typename
    }
  }
`
export const getTemplatesTypes = gql`
  query getTemplatesTypes {
    types {
      id
      typeName
      displayName
      slug
      isSelected @client
      __typename
    }
  }
`
export const putSelectedPhrasesMutation = gql`
  mutation putSelectedPhrases($statement: TemplateConstructedStmtInput) {
    putSelectedPhrases(statement: $statement) @client
  }
`
export const putSelectedFiltersMutation = gql`
  mutation putSelectedFilters(
    $userFilters: TemplateFilterInput
    $userListingRefiners: TemplateSearchInput
  ) {
    putSelectedFilters(
      userFilters: $userFilters
      userListingRefiners: $userListingRefiners
    ) @client
  }
`
export const getSelectedFiltersQuery = gql`
  query getSelectedFilters {
    getSelectedFilters @client(always: true)
  }
`
export const searchWordsQuery = gql`
  query searchWordsQuery {
    searchQuery @client
  }
`
export const clearSelectedFiltersMutation = gql`
  mutation clearSelectedFiltersMutation {
    clearSelectedFilters @client
  }
`
export const isVideosOnlyQuery = gql`
  query isVideosOnlyQuery {
    isVideosOnly @client(always: true)
  }
`
export const durationRangeQuery = gql`
  query durationRangeQuery {
    minDuration @client
    maxDuration @client
  }
`
export const isBasicQuery = gql`
  query isBasicQuery {
    isBasic @client
  }
`
export const partnerQuery = gql`
  query partnerQuery {
    partnerOnly @client
  }
`

export const orderByQuery = gql`
  query orderByQuery {
    orderBy @client
  }
`
export const getSelectedPhrasesQuery = gql`
  query getSelectedPhrasesQuery {
    selectedPhrases @client(always: true)
  }
`
export const toggleFavoriteMutation = gql`
  mutation toggleFavorite($id: ID!) {
    toggleFavorite(templateId: $id) {
      id
      favorited
      type {
        typeName
      }
    }
  }
`
export const toggleLikeMutation = gql`
  mutation toggleLike($id: ID!, $status: TemplateLikeStatus) {
    toggleLike(input: { templateId: $id, status: $status }) {
      id
      type {
        typeName
      }
      userLike {
        status
      }
    }
  }
`
export const toggleSelectedFilterMutation = gql`
  mutation toggleSelectedFilter($id: ID!, $typeName: String!) {
    toggleSelectedFilter(id: $id, __typename: $typeName) @client
  }
`
export const toggleSelectedphraseMutation = gql`
  mutation toggleSelectedPhrase($id: ID!, $dataArr: [String!]) {
    toggleSelectedPhrase(id: $id, dataArr: $dataArr) @client
  }
`
export const mapPhrasesToFiltersQuery = gql`
  query mapPhrasesToFiltersQuery($actions: String, $useCases: String) {
    mapPhrasesToFilters(actions: $actions, useCases: $useCases) {
      categoryNames
      tagNames
    }
  }
`
export const categorizedTemplatesQuery = gql`
  query categorizedTemplates(
    $actions: String
    $useCases: String
    $types: String
    $duration: IntRangeInput
    $platforms: String
    $authenticated: Boolean!
    $first: Int!
    $after: String
    $firstTemplates: Int
    $afterTemplates: String
  ) {
    categorizedTemplates(first: $first, after: $after, actions: $actions) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          name
          slug
          templates(
            first: $firstTemplates
            after: $afterTemplates
            phrases: {
              types: $types
              duration: $duration
              platforms: $platforms
              useCases: $useCases
            }
          ) {
            ...templateDetails
          }
        }
      }
    }
  }
  ${templateFragment}
`
export const createBlankUserImageTemplateMutation = gql`
  mutation createBlankUserImageTemplate($size: Dimension2DCreateInput) {
    createBlankUserImageTemplate(input: { size: $size }) {
      id
      name
    }
  }
`
